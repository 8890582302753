<template>
  <div class="detail">
    <div class="image">
      <img :src="indexImg" style="width: 100%; height: 100%" />
    </div>
    <div class="listHdnav">
      <div class="listHd clearfloat">
        <div class="fl listHdGrop">
          {{ $t('m.listPageOption') }}：<span class="pageTopInner pageTopInnerHome"
            ><a href="/">{{ $t('m.listPageHome') }}</a></span
          >
          <span class="pageTopInner pageTopInnerSub" v-for="(item, index) in navSubList" :key="index">
            &nbsp;<em class="pageTopInnerJt">></em>
            <a :href="isParty ? '/party' : index == 0 ? slideHdParentUrl : item.url">{{ item.name }}</a>
          </span>
          <span class="pageTopInner pageTopInnerDetail">
            <em class="pageTopInnerJt">&nbsp;></em> {{ $t('m.listPageDetail') }}</span
          >
        </div>
      </div>
      <div class="width_100 CLine"></div>
    </div>
    <div class="layout">
      <div class="content">
        <h2 style="margin-bottom:10px; padding-bottom:8px;">{{ newsitem.title }}</h2>
        <div style="background-color: #e7f4fa; height: 2px; margin: 10px auto"></div>
        <div class="ql-snow">
          <div class="text ql-editor">
            <div class="content_body nrstyle" v-html="newsitem.content"></div>
          </div>
        </div>
        <div class="message">
          <div>
            <span>责任编辑：{{ newsitem.editor }}</span>
            {{ $t('m.detailTime') }}：<span>{{ dayjs(newsitem.publishDate).format('YYYY-MM-DD') }}</span>
          </div>
          <!-- <div>
            {{ $t('m.detailView') }}：<span>{{ newsitem.click }}</span
            >{{ $t('m.detailCount') }}
          </div> -->
        </div>
        <div class="detailFooter">
          <div class="tz">
            <div class="u" v-if="newsitem.upArtUuid !== '0'">
              <a target="_blank" :href="newsitem.upArtUuid">上一篇：{{ newsitem.upArtTitle || '无' }}</a>
            </div>
            <div class="d" v-if="newsitem.downArtUuid !== '0'">
              <a target="_blank" :href="newsitem.downArtUuid">下一篇：{{ newsitem.downArtTitle || '无' }}</a>
            </div>
          </div>

          <!-- <a
            v-if="newsitem.upArtUuid && newsitem.upArtUuid !== '0'"
            :href="`/detail/${newsitem.entTChannelUuid}/${newsitem.upArtUuid}`"
          >
            <div class="detailFooter-left">
              <i class="el-icon-back"></i>
              <span>{{ newsitem.upArtTitle }}</span>
            </div>
          </a>
          <a
            v-if="newsitem.downArtUuid && newsitem.downArtUuid !== '0'"
            :href="`/detail/${newsitem.entTChannelUuid}/${newsitem.downArtUuid}`"
          >
            <div class="detailFooter-right">
              <span>{{ newsitem.downArtTitle }}</span>
              <i class="el-icon-right"></i>
            </div>
          </a> -->
        </div>
        <div style="clear:both;"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getChildChannel, getChannelInfoNew, getArtInfo, increaseViews } from '@/api/common'
export default {
  name: 'list',
  data() {
    return {
      parentId: '',
      slideHdTitle: '',
      viewSlideHdParent: true,
      slideHdParentName: '',
      slideHdParentUrl: '',
      slideHdName1: '',
      slideHdName: '',
      slideHdName2: '',
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '新闻动态'
      },
      slideList: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      listData: [],
      newsitem: {},
      indexImg: '',
      navSubList: [],
      isParty: false,
      applicationUuid: '',
      lang: 'zh-CN'
    }
  },
  mounted() {
    var _this = this
    _this.active = _this.$route.params.parentId
    _this.listId = _this.$route.params.id
    _this.parentId = _this.$route.params.parentId
    // let lang = getCookie('lang')
    let lang = localStorage.getItem('lang')
    if (lang == 'en-US') {
      this.applicationUuid = this.webIdEn
    } else {
      this.applicationUuid = this.webId
    }
    _this.getArtInfo()
    _this.increaseViews()
  },
  methods: {
    // 查询二级栏目
    getChildChannel(id) {
      var _this = this
      let data = {
        applicationUuid: this.applicationUuid,
        parentUuid: id
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideList = data
          if (_this.slideList.length) {
            _this.viewSlideHdParent = true
            let url = ''
            if (_this.slideList[0].catalog === 1) {
              url = `/listPage/${_this.slideList[0].entTChannelUuid}`
            } else if (_this.slideList[0].catalog === 2) {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            } else {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            }
            _this.slideHdParentUrl = url
          } else {
            _this.viewSlideHdParent = false
          }
        }
      })
    },
    // getChannelInfoCopy() {
    //   var _this = this
    //   let data = {
    //     channelUuid: _this.$route.params.parentId,
    //     entTApplicationUuid: this.applicationUuid,
    //   }
    //   getChannelInfoNew(data).then(res => {
    //     const { code, data } = res
    //     if (code === 1 && data) {
    //       _this.slideHdName = data.name
    //       if (data.parentUuid == "0") {
    //         _this.slideHdTitle = data.name
    //         _this.viewSlideHdParent = false
    //         if(data.fileName && data.fileName !== ''){
    //           _this.indexImg = data.fileName
    //         }
    //         else{
    //           _this.indexImg = require('../assets/image/partyBuilding.jpg')
    //         }
    //         return
    //       }
    //       _this.getChildChannel(data.parentUuid)

    //       let params = {
    //         channelUuid: data.parentUuid,
    //         entTApplicationUuid: this.applicationUuid,
    //       }
    //       getChannelInfoNew(params).then(res => {
    //         const { code, data } = res
    //         if (code === 1 && data) {
    //           _this.slideHdParentName = data.name
    //           _this.slideHdTitle = data.name
    //           if(data.fileName && data.fileName !== ''){
    //             _this.indexImg = data.fileName
    //           }
    //           else{
    //             _this.indexImg = require('../assets/image/partyBuilding.jpg')
    //           }
    //         }
    //       })
    //     }
    //   })
    // },
    getChannelInfo(params) {
      var _this = this
      getChannelInfoNew(params).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          let url = ''
          if (data.catalog === 1) {
            url = `/listPage/${data.entTChannelUuid}`
          } else if (data.catalog === 2) {
            url = `/list/${data.entTChannelUuid}`
          } else {
            url = `/list/${data.entTChannelUuid}`
          }
          let list = {
            name: data.name,
            entTChannelUuid: data.entTChannelUuid,
            url: url
          }
          _this.navSubList.unshift(list)
          if (data.parentUuid == '0') {
            if (data.fileName && data.fileName !== '') {
              _this.indexImg = data.fileName
            } else {
              _this.indexImg = require('../assets/image/partyBuilding.jpg')
            }
            // 判断是否党建
            if (data.entTChannelUuid == '0181') {
              _this.isParty = true
            }
            _this.getChildChannel(data.entTChannelUuid)
            return
          } else {
            let query = {
              channelUuid: data.parentUuid,
              applicationUuid: this.applicationUuid
            }
            _this.getChannelInfo(query)
          }
        } else {
          _this.indexImg = require('../assets/image/isbg.png')
        }
      })
    },
    getArtInfo() {
      var _this = this
      var applicationId = ''
      if (_this.$route.params.parentId == '00190001') {
        applicationId = _this.webIdGroup
      } else {
        applicationId = _this.webId
      }
      let data = {
        entTApplicationUuid: applicationId,
        entTArticleUuid: _this.$route.params.id
      }
      getArtInfo(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsitem = data
          let params = {
            channelUuid: data.entTChannelUuid,
            applicationUuid: this.applicationUuid
          }
          _this.getChannelInfo(params)
        }
      })
    },
    increaseViews() {
      var _this = this
      let data = {
        entTArticleUuid: _this.$route.params.id
      }
      increaseViews(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          // _this.dataView = data
          // console.log(111,_this.dataView)
        }
      })
    }
  }
}
</script>

<style>
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff2?t=1661313604254') format('woff2'),
    url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff?t=1661313604254') format('woff'),
    url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.ttf?t=1661313604254') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}
.detail {
  background-color: #f2f2f2;
}
.detail .content_body p {
  line-height: 1.5em !important;
  text-indent: 2em;
  text-align: justify;
  padding: 10px 0;
}
.nrstyle {
  font-family: '宋体';
  font-size: 18px;
  line-height: 30px;
}
.nrstyle img {
  max-width: 90% !important;
  margin: auto !important;
}
.detail .image {
  width: 100%;
  height: 417px;
  overflow: hidden;
  min-width: 1200px;
  max-height: 330px;
  display: flex;
  margin: 0 auto;
  border: none;
  position: relative;
  z-index: 99;
}
.layout {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 30px;
}
.detail .content {
  min-width: 1000px !important;
  min-height: 300px;
  /* display: flex; */
  /* margin: 20px 0 0px 0; */
  margin-top: 2px;
  padding: 60px 100px;
  font-family: '仿宋';
  font-size: 16pt;
  background-color: #fff;
  box-shadow: 0 1px 4px #333;
}
.listHdnav {
  background-color: #fff;
}
.detail .listHd {
  width: 1200px;
  margin: 0 auto;
  height: 50px;
  display: flex;
  line-height: 50px;
}
.CLine {
  height: 24px;
  overflow: hidden;
  background: url(../assets/image/CLine.png);
  position: absolute;
  z-index: 2;
  overflow: hidden;
}
.width_100 {
  width: 100% !important;
}
.detail .listHdGrop {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
  opacity: 0.6;
}
.detail .content h2,
.message {
  font-family: Microsoft YaHei;
  color: #333;
  text-align: center;
}
.detail .content h2 {
  min-height: 40px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 36px;
  padding-bottom: 8px;
}
.detail .content h2,
.message {
  font-family: Microsoft YaHei;
  color: #333;
  text-align: center;
}
.detail .message {
  height: 30px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  opacity: 0.6;
  color: #caccce;
  margin: 10px 0;
  padding-bottom: 10px;
}
.detail .message div {
  display: inline;
  line-height: 40px;
  padding: 0 10px;
}
.ql-snow,
.ql-snow * {
  box-sizing: border-box;
}
.text {
  color: #333;
  padding: 20px;
  /* font-size: 16px; */
  line-height: 40px;
  margin: 0 auto;
}
.ql-editor {
  width: 100%;
  box-sizing: border-box;
  line-height: 1.5em;
  text-indent: 2em;
  text-align: justify;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  -o-tab-size: 4;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  /* white-space: pre-wrap; */
  word-wrap: break-word;
}
.detailFooter {
  /* height: 60px;
  position: relative;
  line-height: 60px; */
  border-top: 1px #f7ebec solid;
  padding: 10px 0px;
  margin-top: 10px;
  text-align: left;
  font-family: 微软雅黑, Microsoft YaHei, sans-serif;
}
.detailFooter .tz a {
  font-size: 14px;
  display: inline-block;
  padding: 5px 0px;
}
/* .detailFooter-left {
  position: absolute;
  left: 0;
} */
/* .detailFooter-right {
  position: absolute;
  right: 0;
  text-align: right;
} */
.detailFooter-left,
.detailFooter-right {
  width: 100%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
  cursor: pointer;
  height: 100%;
  /* display: flex; */
}
.detailFooter-left i,
.detailFooter-right i {
  /* position: relative;
  top: 35%; */
  margin: 0 10px;
  font-size: 18px;
}
.detailFooter-left i,
.detailFooter-right i {
  /* position: relative;
  top: 35%; */
  margin: 0 10px;
  font-size: 18px;
}
.detailFooter-left span,
.detailFooter-right span {
  display: inline-block;
  width: 400px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
